import { ProductVariationWithProductResponse } from "@/web-client/api";
import useDevice from "@/hooks/useDevice";
import { FC } from "react";
import FlexibleGrid from "@/components/FlexibleGrid";
import ModalEntityListWrapper from "@/components/ModalEntityListWrapper";

interface Props {
  productVariations: ProductVariationWithProductResponse[];
  onRender: (productVariation) => JSX.Element;
}

const ProductVariationItemList: FC<Props> = ({
  productVariations,
  onRender,
}): JSX.Element => {
  const { isSp } = useDevice();

  return (
    <ModalEntityListWrapper responses={productVariations}>
      <FlexibleGrid
        minWidth={isSp ? 120 : 200}
        rowGap={isSp ? 12 : 24}
        columnGap={isSp ? 12 : 24}
      >
        {productVariations.map((p) => (
          <li key={p.id} className="[&>*]:w-full [&>*]:h-full">
            {onRender(p)}
          </li>
        ))}
      </FlexibleGrid>
    </ModalEntityListWrapper>
  );
};

export default ProductVariationItemList;
